// Libararies
import _ from 'lodash';
import React from 'react';

// Supermove
import {PageLoader, Space} from '@supermove/components';
import {gql} from '@supermove/graphql';
import {
  useState,
  useQuery,
  useModal,
  useScrollView,
  useNavigationDOM,
  useDebouncedCallback,
  useTextInput,
  useEffect,
} from '@supermove/hooks';
import {
  CommunicationModel,
  CommunicationKindModel,
  ProjectModel,
  UserModel,
} from '@supermove/models';

// App
import Line from '@shared/design/components/Line';
import TextMessageTemplateCategory from '@shared/modules/Sms/enums/TextMessageTemplateCategory';
import EditClientModal from 'modules/Client/Show/components/EditClientModal';
import {useCommunicationNewSseSubscription} from 'modules/Communication/hooks/useCommunicationNewSseSubscription';
import CommunicationWidgetTags from 'modules/Project/V2/Show/components/widgets/CommunicationsWidget/CommunicationWidgetTags';
import CommunicationsWidgetBody from 'modules/Project/V2/Show/components/widgets/CommunicationsWidget/CommunicationsWidgetBody';
import CommunicationsWidgetFooter from 'modules/Project/V2/Show/components/widgets/CommunicationsWidget/CommunicationsWidgetFooter';
import CommunicationsWidgetHeader from 'modules/Project/V2/Show/components/widgets/CommunicationsWidget/CommunicationsWidgetHeader';

const CommunicationsWidget = ({project}: {project: ProjectModel}) => {
  const editClientModal = useModal({name: 'Edit Client Modal - Project Emails Widget'});
  const conversationsScrollView = useScrollView();
  const {params} = useNavigationDOM();
  const searchInput = useTextInput();
  const [searchQuery, setSearchQuery] = useState('');
  const [conversationKinds, setConversationKinds] = useState<CommunicationKindModel[]>([
    'EMAIL',
    'SMS',
    'CALL',
  ]);

  const handleChangeQuery = useDebouncedCallback((query) => {
    setSearchQuery(query);
  }, 250);

  const {loading, data, refetch} = useQuery<{
    viewer: UserModel;
    user: UserModel;
    project: ProjectModel;
    projectCommunications: CommunicationModel[];
  }>(CommunicationsWidget.query, {
    fetchPolicy: 'network-only',
    variables: {
      userUuid: project.client.primaryContact.uuid,
      projectId: project.id,
      projectUuid: project.uuid,
      categories: [TextMessageTemplateCategory.CUSTOMER],
      searchQuery,
      conversationKinds,
    },
  });

  const debouncedRefetch = useDebouncedCallback(refetch, 1000);
  const {latestEvent, subscribeToProject, unsubscribe} = useCommunicationNewSseSubscription();

  useEffect(() => {
    subscribeToProject(project.id);

    return () => {
      unsubscribe();
    };
  }, [project]);

  useEffect(() => {
    if (latestEvent && project.organization.features.isEnabledRealTimeMessages) {
      debouncedRefetch();
    }
  }, [latestEvent]);

  return (
    <React.Fragment>
      <PageLoader loading={loading} data={data}>
        {({loadedData}) => {
          const {textMessageTemplatesByCategory} = loadedData.project.organization;
          return (
            <React.Fragment>
              <Space height={12} />
              <CommunicationWidgetTags
                activeTags={conversationKinds}
                handleChangeTags={setConversationKinds}
              />
              <Space height={12} />
              <Line />
              <CommunicationsWidgetHeader
                searchInput={searchInput}
                handleChangeQuery={handleChangeQuery}
                isLoading={loading}
              />
              <CommunicationsWidgetBody
                viewer={loadedData.viewer}
                customer={loadedData.user}
                conversations={loadedData.projectCommunications}
                conversationsScrollView={conversationsScrollView}
                handleMissingPhoneNumber={editClientModal.handleOpen}
              />
              <CommunicationsWidgetFooter
                customer={loadedData.user}
                viewer={loadedData.viewer}
                refetch={refetch}
                projectId={project.id}
                textMessageTemplates={textMessageTemplatesByCategory}
                selectedTextMessageTemplate={_.find(
                  textMessageTemplatesByCategory,
                  (template) => template.uuid === params.textMessageTemplateUuid,
                )}
              />
            </React.Fragment>
          );
        }}
      </PageLoader>
      <EditClientModal
        key={editClientModal.key}
        isOpen={editClientModal.isOpen}
        handleClose={editClientModal.handleClose}
        clientUuid={project.client.uuid}
        // A refetch is not needed since the mutation response updates client cache
        refetch={() => {}}
      />
    </React.Fragment>
  );
};

// --------------------------------------------------
// Data
// --------------------------------------------------
CommunicationsWidget.query = gql`
  ${CommunicationsWidgetBody.fragment}
  ${CommunicationsWidgetFooter.fragment}

  query CommunicationsWidget(
    $userUuid: String!,
    $projectId: Int!,
    $projectUuid: String!,
    $categories: [String],
    $searchQuery: String,
    $conversationKinds: [String],
  ) {
    ${gql.query}
    viewer {
        id
        ...CommunicationsWidgetFooter_Viewer
        ...CommunicationsWidgetBody_Viewer
      }
      user(uuid: $userUuid) {
        id
        ...CommunicationsWidgetFooter_User
        ...CommunicationsWidgetBody_User
      }
      project(uuid: $projectUuid) {
        id
        organization {
          id
          textMessageTemplatesByCategory(categories: $categories) {
            id
            uuid
            ...CommunicationsWidgetFooter_TextMessageTemplate
          }
        }
      }
      projectCommunications(projectId: $projectId, searchQuery: $searchQuery, conversationKinds: $conversationKinds) {
        id
        email {
          id
          thread {
            id
            uuid            
          }
        }
        ...CommunicationsWidgetBody_Communication
      }
  }
`;

CommunicationsWidget.fragment = gql`
  fragment CommunicationsWidget on Project {
    id
    uuid
    client {
      id
      uuid
      primaryContact {
        id
        uuid
      }
    }
    organization {
      id
      slug
      features {
        isEnabledRealTimeMessages: isEnabled(feature: "REAL_TIME_MESSAGES")
      }
    }
  }
`;

export default CommunicationsWidget;
